import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";

import jwtDecode from "jwt-decode";
import JsonData from "./data/data.json";
import "./App.css";
import { api } from "./config.js";
import http from "./services/httpService";
import Loader from "./pages/Loader.jsx";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        const jwt = localStorage.getItem("token");
        const user_jwt = jwtDecode(jwt);
        const { data } = await http.get(`${api.usersEndPoint}${user_jwt._id}`);
        setUser(data);
      } catch (ex) {}
    }
    fetchUser();
    setLandingPageData(JsonData);
  }, []);
  

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Loader data={landingPageData} />}/>
    </Routes>
    </BrowserRouter>
  );
};

export default App;
